import axios from 'axios'

const state = {}

const getters = {}

const actions = {
  register (context, informations) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/register',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: informations
      }).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
