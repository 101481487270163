<template>
    <div ref="event_block" class="v-cal-event-item"
         :title="event.startTime | formatEventTime(use12) + ' - ' + event.displayText"
         :class="eventClasses"
         @click.stop="eventClicked"
         :style="eventStyles">
        <span class="v-cal-event-time">{{ event.startTime | formatEventTime(use12) }}</span>
        <span class="v-cal-event-name">{{ event.displayText }}</span>
    </div>
</template>

<script>
    import moment from 'moment';
    import { EventBus } from './EventBus';

    export default {
        name: "EventItem",
        props: {
            event: {
                type: Object,
                required: true
            },
            use12: {
                type: Boolean,
                required: true
            },
            hasDynamicSize: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                ancestorHeight: 0
            }
        },
        mounted() {
            if (this.hasDynamicSize ) {
                this.getAndSetAncestorHeight();
                window.addEventListener('resize', this.getAndSetAncestorHeight);
            }
        },
        beforeDestroy() {
            if ( this.hasDynamicSize )
                window.removeEventListener('resize', this.getAndSetAncestorHeight);
        },
        methods: {
            eventClicked() {
                EventBus.$emit('event-clicked', this.event);
            },
            getAndSetAncestorHeight() {
                this.ancestorHeight = this.findAncestor(this.$refs.event_block, 'v-cal-day__hour-content').offsetHeight;
            },
            findAncestor (el, cls) {
                while ((el.parentElement) && (el = el.parentElement) && !el.classList.contains(cls)) ;
                return el;
            }
        },
        computed: {
            displayHeightPx() {
                const end = moment(this.event.endTime);

                const hours = end.diff(this.event.startTime, 'hours', true);
                const bordersOffset = hours - 1;
                return  ( hours * this.ancestorHeight ) + bordersOffset;
            },
            displayHeightPourcent() {
                if(!this.event.endTime){
                    return "100%"
                }
                const end = moment(this.event.endTime)

                const hours = end.diff(this.event.startTime, 'hours', true);
                const bordersOffset = hours - 1;
                return "calc("+(hours * 100)+"% + "+ bordersOffset +"px)";
            },
            eventStyles() {

                let styles = [];
                styles.push({
                    'backgroundColor': this.event.color,
                    'color': this.event.color,
                    'left': 0,
                    'position': 'absolute'
                });

                if ( this.hasDynamicSize ) {
                    if(this.ancestorHeight == 0 || this.event.endDate == null){
                        styles.push({
                            'height': this.displayHeightPourcent,
                        });
                    }
                    else {
                        styles.push({
                            'height': this.displayHeightPx + 'px',
                        });
                    }

                    if ( this.event.overlaps > 0 ) {
                        const width = 100 / ( this.event.overlaps + 1 );
                        styles.push({
                            'width': width + '%',
                            'left': width + '%'
                        });
                    }

                    if ( this.event.startTime && this.event.startTime.minutes() > 0 && this.event.overlaps === 0 ) {
                        const distance = ( this.ancestorHeight / 60 ) * this.event.startTime.minutes();
                        styles.push({
                            'top': distance + 'px'
                        });
                    }
                }

                return styles;
            },
            eventClasses() {
                var classes = {
                    'is-overlapping': this.event.overlaps > 0
                }
                if (this.event.client) {
                    classes["rvAvecClient"] = "texte pour remplir"
                } else if (this.event.professionnelId) {
                    classes["rvAvecProfessionnel"] = "texte pour remplir"
                } else if (this.event.type) {
                    classes["vacances"] = "texte pour remplir"
                } else {
                    classes["dispo"] = "texte pour remplir"
                }
                return classes
            }
        },
        filters: {
            formatEventTime(hour, use12 = false) {
                if ( !hour )
                    return '';

                if ( use12 )
                    return hour.format( hour.minutes() > 0 ? 'h:mma' : 'h:mma' ).slice(0, -1);

                return hour.format( hour.minutes() > 0 ? 'HH:mm' : 'HH:mm' );
            }
        },
    }
</script>

<style scoped>

</style>