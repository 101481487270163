<template>
  <div>
    <div>
      <form v-on:submit.prevent="sendEmail">
        <img class="banniere" src ="@/assets/banniere.jpg">
        <h1>Réinitialisation du mot de passe</h1>
        <p>Entrez votre email.</p>
        <b-form-input @blur="$v.email.$touch()" required type="email" name="email" style="width:50%; margin-right:auto;margin-left:auto;" placeholder="Courriel" v-model="email" />
        <div class="errorMessage" v-if="!$v.email.required && $v.email.$error">*Champ requis</div>
        <b-button type="Submit" >ENVOYER</b-button>
        <p>{{message}}</p>
      </form>
    </div>
    <b-modal id="modal-chargement" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Connexion">
      Chargement...
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'motDePasseOublie',
  data () {
    return {
      email: '',
      message: '',
      errors: []
    }
  },
  validations: {
    email: {
      required
    }
  },
  methods: {
    sendEmail () {
      this.errors = []
      if (!this.$v.$invalid) {
        this.$bvModal.show('modal-chargement')
        this.$store.dispatch('forgotPassword', { email: this.email }).then(
          response => {
            this.message = 'Un courriel de confirmation a été envoyé.'
            this.$bvModal.hide('modal-chargement')
          },
          () => {
            this.message = 'Un erreur est survenue'
            this.$bvModal.hide('modal-chargement')
          }
        )
      }
    }
  }
}
</script>

<style scoped>
button{
  margin-bottom: 20px;
  background-color: #ffc90e !important;
  color: #fff;
  font-family: roboto;
  font-size: 20px;
  padding:15px;
  margin-top:50px;
  border-radius: 5px;
  border-color:#ffc90e;
}

button:hover
{
  background-color:#29689c !important;

}
.errorMessage
{
  color:red;
  font-size: 15px;
}
.banniere
{
  height:35vh;
}
@media screen and (max-width: 1200px) {
.banniere {
  height:30vh;
  }
  h1{
  font-size:30px;
  margin-right:20px;
  margin-lefT:20px;
  }

}
</style>
