<template>
    <AdminPageWrapper>
      <div class="content">
        <div class="content-top">
          <div class="content-top-top">
            <span>Recherche</span><b-input class="top-searchbar" type="search" v-model="query" @input="updateTableData" />
          </div>
          <div class="content-top-bottom">
            <b-button :to="{ name: 'pageGestionCategorie' }">Ajouter une catégorie</b-button>
          </div>
        </div>
        <div class="content-middle">
          <b-table ref="categoriesTable" :fields="fields" :items="myProvider">
            <template #cell(edit)="data">
              <b-button :to="{ name: 'pageGestionCategorie', params: { id: data.item.id } }">Modifier</b-button>
            </template>
            <template #cell(delete)="data">
              <b-button variant="danger" @click="deleteItem(data.item.id)">Supprimer</b-button>
            </template>
          </b-table>
        </div>
      </div>
    </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from '@/components/AdminPageWrapper.vue'
export default {
  name: 'ListeCategories',
  components: {
    AdminPageWrapper
  },
  methods: {
    updateTableData () {
      this.$refs.categoriesTable.refresh()
    },
    deleteItem (id) {
      this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer la catégorie?', {
        title: 'Supprimer',
        cancelTitle: 'Non',
        okTitle: 'Oui'
      }).then((canDelete) => {
        if (canDelete) {
          this.$store.dispatch('deleteCategory', id).then(() => {
            this.isCategoriesLoaded = false
            this.updateTableData()
          }).catch((err) => {
            let erreur = err.response.data
            switch (erreur) {
              case 'professionnel_lien_existant': {
                erreur = 'Il existe encore un professionnel avec cette catégorie'
                break
              }
            }
            this.$toast.error(erreur, { duration: 5000 })
          })
        }
      })
    },
    myProvider (ctx, callback) {
      const whenCategoryLoaded = () => {
        const query = this.query
        const categoriesWithQuery = this.categories.filter(element => element.name.includes(query))
        callback(categoriesWithQuery)
      }
      if (!this.isCategoriesLoaded) {
        this.$store.dispatch('getAllCategories').then((categories) => {
          this.categories = categories
          this.isCategoriesLoaded = true
          whenCategoryLoaded()
        }).catch(() => {
          callback()
        })
      } else {
        whenCategoryLoaded()
      }
      return null
    },
    edit (data) {
      // To add
    }
  },
  data () {
    return {
      isCategoriesLoaded: false,
      ordres: [],
      categories: [],
      query: '',
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'ordre.nom',
          label: 'Ordre',
          sortable: true
        },
        {
          key: 'price_per_20minutes',
          label: 'Prix par 20 min ($)',
          sortable: true
        },
        {
          key: 'edit',
          label: 'Modifier'
        },
        {
          key: 'delete',
          label: 'Supprimer'
        }
      ]
    }
  }
}
</script>

<style scoped>
/* >>> button {
  background-color: #29689c !important;
} */
.tblImg {
  width: 50px;
  height: 50px;
}
.content-middle {
  display: flex;
  flex-direction: column;
  width: 99vw;
  align-items: flex-start;
  background-color: white;
}
.content-top-top{
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.content-top-bottom{
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.content-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.top-searchbar {
  min-width: 200px;
  width: 50%;
  max-width: 100%;
  margin-left: 10px;
}
</style>
