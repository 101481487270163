<template>
<div class="admin-container">
    <div class="admin-linkbar">
        <a v-for="link in linkList" :key="link.url" :href="link.url">{{ link.displayText }}</a>
    </div>
    <div class="admin-content">
        <slot />
    </div>
</div>
</template>

<script>
export default {
  name: 'AdminPageWrapper',
  data () {
    return {
      linkList: [
        {
          displayText: 'Liste professionnels',
          url: '/admin'
        },
        {
          displayText: 'Liste utilisateurs',
          url: '/admin/utilisateurs'
        },
        {
          displayText: 'Liste ordres',
          url: '/admin/liste-ordres'
        },
        {
          displayText: 'Liste catégories',
          url: '/admin/liste-categories'
        },
        {
          displayText: "Paneau d'administration microsoft",
          url: 'https://admin.microsoft.com/'
        }
      ]
    }
  }
}
</script>

<style scoped>
.admin-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.admin-content {
  width: 100%;
}
.admin-linkbar {
  background-color: #29689c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.admin-linkbar a {
  color: white;
  padding: 0.5rem 1rem;
  flex-basis: auto
}
.admin-linkbar a:hover {
  text-decoration: none;
}
</style>
