import { render, staticRenderFns } from "./AdminPageWrapper.vue?vue&type=template&id=21ed6538&scoped=true&"
import script from "./AdminPageWrapper.vue?vue&type=script&lang=js&"
export * from "./AdminPageWrapper.vue?vue&type=script&lang=js&"
import style0 from "./AdminPageWrapper.vue?vue&type=style&index=0&id=21ed6538&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ed6538",
  null
  
)

export default component.exports