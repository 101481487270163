<template>
    <AdminPageWrapper>
      <div>
        <div class="buttonNouveauContainer"><b-button class="buttonNouveau" type="submit" @click="ajouterProfessionnel()">Ajouter Nouveau</b-button></div>
        <br>
        <table class="tableauProf">
          <tr>
            <th>ID</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Catégorie</th>
            <th>Est enregistré avec Microsoft</th>
            <th>Taxable</th>
            <th>Modifier</th>
            <th>Supprimer</th>
          </tr>
          <tr v-for="prof in listeProfessionnels" v-bind:key="prof.id">
            <td>{{ prof.id }}</td>
            <td>{{ prof.first_name }}</td>
            <td>{{ prof.last_name }}</td>
            <td>{{ prof.categoryName }}</td>
            <td v-if="prof.hasBeenAddedToMicrosoftGraph">Oui</td>
            <td v-else>Non</td>
            <td><b-form-checkbox :checked="prof.isTaxable == 1" @change="isTaxableChange( prof.id , $event)"/></td>
            <td><b-button class="buttonModifier" type="submit" @click="modifierProfessionnel(prof.id)">Modifier</b-button></td>
            <td><b-button @click="supprimerProfessionnel($event)">Supprimer</b-button></td>
          </tr>
        </table>
      </div>
    </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from '../components/AdminPageWrapper.vue'
export default {
  name: 'PageInfoProfessionnel',
  components: {
    AdminPageWrapper
  },
  computed: {
    listeProfessionnels () {
      return this.$store.getters.listeProfessionnels
    }
  },
  methods: {
    isTaxableChange (userId, taxable) {
      this.$store.dispatch('saveTaxableForUser', { userId: userId, taxable })
    },
    modifierProfessionnel: function (idProfessionnel) {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'pageGestionCompteProf', params: { id: idProfessionnel } })
    },
    ajouterProfessionnel: function () {
      history.pushState({}, document.title, window.location.href)
      this.$router.push({ name: 'pageGestionCompteProf' })
    },
    supprimerProfessionnel (e) {
      this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cet utilisateur?', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        // value retourne true si le user fait Oui, false si le user fait Non et null si il fait le x ou esc
        if (value) {
          this.$store.dispatch('deleteProfessionnel', e.path[2].cells[0].innerHTML)
        }
      })
    }
  },
  created () {
    this.$store.dispatch('fetchAllProfessionnels')
  }
}
</script>

<style scoped>
.tableauProf {
  margin: auto;
}
.tableauProf td, .tableauProf th {
  border: 1px solid #ddd;
  padding: 8px;
}
  .buttonNouveauContainer{
    display: block;
  }
</style>
