<template>
  <AdminPageWrapper>
    <div class="container" v-if="isReady && isOrdreLoaded">
    <b-form v-on:submit.prevent="soumettre" class="form-container">
      <template v-for="field in fields">
        <b-form-group
          :label="field.label"
          :label-for="field.id"
          :key="field.id"
          class="inputGroup"
        >
          <b-form-select v-if="field.type === 'select'" :id="field.id" v-model="item[field.id]">
            <b-form-select-option v-for="option in field.options()" v-bind:key="option[field.valueField]" :value="option[field.valueField]">
              {{ option[field.textField] }}
            </b-form-select-option>
          </b-form-select>
          <b-form-textarea v-else-if="field.type === 'textarea'" type="text" :id="field.id" :placeholder="field.label" v-model="item[field.id]" />
          <b-form-input v-else :required="field.required" :type="field.type" :id="field.id" :placeholder="field.label" v-model="item[field.id]" />
        </b-form-group>
      </template>
      <div class="btnContainer">
        <b-button class="buttonSoumettre" type="submit">Soumettre</b-button>
        <b-button class="buttonBack" @click="back()">Annuler</b-button>
      </div>
      <span style ="color:red" v-for="erreur in listeErreurs" v-bind:key="erreur">{{ erreur }}<br></span>
      </b-form>
      <b-modal id="modal-chargement" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered hide-footer title="Chargement">
        Chargement...
      </b-modal>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from '../components/AdminPageWrapper.vue'
export default {
  name: 'PageGestionCategorie',
  components: {
    AdminPageWrapper
  },
  data () {
    return {
      item: {},
      listeErreurs: [],
      isOrdreLoaded: false,
      retourServeur: '',
      isReady: false,
      ordres: []
    }
  },
  computed: {
    siNouveau () {
      return this.$route.params.id == null
    },
    fields () {
      const fields = [
        {
          id: 'name',
          label: 'Nom',
          type: 'text',
          required: true
        },
        {
          id: 'price_per_20minutes',
          label: 'Prix par 20 min ($)',
          type: 'number',
          required: true
        },
        {
          id: 'ordreId',
          label: 'Ordre',
          type: 'select',
          valueField: 'id',
          textField: 'nom',
          options: this.getOrdres
        }
      ]
      return fields
    }
  },
  methods: {
    getOrdres () {
      return this.ordres
    },
    soumettre () {
      this.listeErreurs = []
      if (!this.item.name) {
        this.listeErreurs.push('Le nom est requis')
      }
      if (!this.item.price_per_20minutes) {
        this.listeErreurs.push('Le prix est requis')
      }
      if (this.listeErreurs.length === 0) {
        this.$bvModal.show('modal-chargement')
        this.retourServeur = this.$store.dispatch('registerOrUpdateCategory', this.item).then((response) => {
          if (response.status === 200) {
            if (this.$route.params.id) {
              this.$toast.success('La catégorie à été modifié', { duration: 5000 })
            } else {
              history.pushState({}, document.title, window.location.href)
              this.$toast.success('La catégorie à été créé', { duration: 5000 })
            }
            this.$router.push({ name: 'ListeCategories' })
            this.$bvModal.hide('modal-chargement')
          }
        }).catch((err) => {
          const errors = err.response.data
          console.error(errors)
          this.$bvModal.hide('modal-chargement')
        })
      }
    },
    back () {
      this.$router.push({ path: '/admin/ordres' })
    }
  },
  created () {
    this.$store.dispatch('getAllOrdres').then((ordres) => {
      ordres.push({
        id: null,
        nom: 'Aucun'
      })
      this.ordres = ordres
      this.isOrdreLoaded = true
    })
    if (!this.siNouveau) {
      this.$store.dispatch('getCategory', this.$route.params.id).then((item) => {
        this.item = item
        this.isReady = true
      })
    } else {
      this.item = {}
      this.isReady = true
    }
  }
}
</script>

<style scoped>
img{
  width: 100px;
  height: 100px;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.inputGroup {
  width: 100%;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
</style>
