import Vue from 'vue'
import VueRouter from 'vue-router'
import Payment from '../views/Payment.vue'
import MultiFactorAuth from '../views/MultiFactorAuth.vue'
import Home from '../views/Home.vue'
import ListeProfessionnels from '../views/ListeProfessionnels.vue'
import PageInfoProfessionnel from '../views/PageInfoProfessionnel.vue'
import PageAdmin from '../views/PageAdmin.vue'
import PageGestionCompteProf from '../views/PageGestionCompteProf.vue'
import PageGestionCompte from '../views/PageGestionCompte.vue'
import MotDePasseOublie from '@/components/MotDePasseOublie.vue'
import ProfilUtilisateur from '../views/ProfilUtilisateur.vue'
import ModificationProfil from '../views/ModificationProfil.vue'
import NouveauMotDePasse from '@/components/nouveauMotDePasse.vue'
import ListeUtilisateur from '../views/ListeUtilisateur.vue'
import ListeOrdres from '../views/ListeOrdres.vue'
import ListeCategories from '../views/ListeCategories.vue'
import PageGestionOrdre from '../views/PageGestionOrdre.vue'
import PageGestionCategorie from '../views/PageGestionCategorie.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/multifactor',
    name: 'MultiFactor',
    component: MultiFactorAuth
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: {
      requiresLogin: true
    },
    props: true
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/calendrier',
    name: 'Calendrier',
    component: ProfilUtilisateur,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/profil',
    name: 'Profil',
    component: ModificationProfil,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/professionnels',
    name: 'listeProfessionnels',
    component: ListeProfessionnels,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/professionnels/:id',
    name: 'infoProfessionnel',
    component: PageInfoProfessionnel,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/admin',
    name: 'pageAdmin',
    component: PageAdmin,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/gestionCompteProf/:id?',
    name: 'pageGestionCompteProf',
    component: PageGestionCompteProf,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/gestionCompte/:id?',
    name: 'pageGestionCompte',
    component: PageGestionCompte,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/mot-de-passe',
    name: 'motDePasseOublie',
    component: MotDePasseOublie
  },
  {
    path: '/newPassword',
    name: 'nouveauMotDePasse',
    component: NouveauMotDePasse
  },
  {
    path: '/admin/utilisateurs',
    name: 'ListeUtilisateur',
    component: ListeUtilisateur,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/liste-ordres',
    name: 'ListeOrdres',
    component: ListeOrdres,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/ordre/:id?',
    name: 'pageGestionOrdre',
    component: PageGestionOrdre,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/liste-categories',
    name: 'ListeCategories',
    component: ListeCategories,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/admin/category/:id?',
    name: 'pageGestionCategorie',
    component: PageGestionCategorie,
    meta: {
      requiresAdmin: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  var isLoggedIn = sessionStorage.getItem('token')
  var isAdmin = parseInt(sessionStorage.getItem('role')) === 1
  if (to.matched.some(record => record.meta.requiresLogin) && !isLoggedIn) {
    next({ name: 'Home' })
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (isLoggedIn && isAdmin) {
      next()
    } else {
      next({ name: 'Home' })
    }
  } else if (to.matched.some(record => record.name === 'Home')) {
    if (isLoggedIn) {
      if (isAdmin) {
        next({ name: 'pageAdmin', query: to.query })
      } else {
        next({ name: 'listeProfessionnels' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
