import axios from 'axios'

const state = {
}

const getters = {
}

const actions = {
  sauvegarderNouveauPermis ({ commit }, body) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData()
      var x
      for (x in body) {
        if (x !== body.fichier) {
          bodyFormData.append(x, body[x])
        }
      }
      if (body.fichier) {
        bodyFormData.append('fichier', body.fichier)
      }
      axios({
        url: '/add_permi',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  sauvegarderPermisExistant ({ commit }, body) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData()
      var x
      for (x in body) {
        bodyFormData.append(x, body[x])
      }
      axios({
        url: '/update_permi',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  supprimerPermis ({ commit }, body) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData()
      var x
      for (x in body) {
        bodyFormData.append(x, body[x])
      }
      axios({
        url: '/delete_permi',
        method: 'post',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: bodyFormData,
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
